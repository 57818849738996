import * as React from "react"

import Layout from "components/layout"
import { Trainingszeiten } from "components/trainingszeiten"
import Headline from "components/general/headline"
import MatchdaysManual from "components/matchdays"

const DatesPage = () => {
  const seo = {
    metaTitle: "Termine",
    metaDescription: "Aktuelle Termine zu Spieltagen und Trainingszeiten",
  }

  return (
    <Layout seo={seo}>
      <div className="main-box">
        <Headline headline="Termine" subHeadline="Training, Spieltage, etc." />
        <h3 className="h3 mt-4">Trainingszeiten</h3>

        <Trainingszeiten />
        <MatchdaysManual />
        {/* <Matchdays id={3111} /> */}
      </div>
    </Layout>
  )
}

export default DatesPage
